import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { SnotifyService } from 'ng-snotify';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public loggedIn: boolean;

    navbarOpen: boolean = false;

    constructor(
        private auth: AuthService,
        private router: Router,
        private notify: SnotifyService
    ) { }

    ngOnInit() {
        this.auth.authStatus.subscribe( value => this.loggedIn = value);
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

    logout() {
        TokenService.remove();
        this.auth.changeAuthStatus(false);
        this.notify.success('Wylogowałaś/eś się.', {timeout: 2000});
        this.router.navigateByUrl('/login');
    }
}
