import { Component, OnInit } from '@angular/core';
import { ApicallsService } from '../../services/apicalls.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { ExpirationCheckerService } from '../../services/expiration-checker.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public form = {
        email: null,
        password: null,
        remember: null
    };

    public error = null;

    constructor(
        private apiCalls: ApicallsService,
        private router: Router,
        private auth: AuthService,
        private notify: SnotifyService,
        private expires: ExpirationCheckerService
    ) { }

    onSubmit() {
        this.apiCalls.login(this.form).subscribe(
            data => {
                this.notify.success('Jesteś zalogowana/ny!', {timeout: 2000});
                this.handleResponse(data);
            },
            error => {
                this.notify.error(error.error.error);
                this.handleError(error);
            }
        );
    }

    handleResponse(data) {
        TokenService.handle(data.access_token);
        this.auth.changeAuthStatus(true);
        this.router.navigateByUrl('/profile');
    }

    handleError(error) {
        this.error = error.error.error;
    }

    ngOnInit() {
        this.expires.tokenExpired();
    }
}
