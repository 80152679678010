import { Component, OnInit } from '@angular/core';
import { ExpirationCheckerService } from '../../services/expiration-checker.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    public loggedIn: boolean;

    constructor(
        private expires: ExpirationCheckerService
    ) { }

    ngOnInit() {
        this.expires.tokenExpired();
    }

}
