import { Component, OnInit } from '@angular/core';
import { ApicallsService } from '../../../services/apicalls.service';
import { SnotifyService } from 'ng-snotify';
import { ExpirationCheckerService } from '../../../services/expiration-checker.service';

@Component({
    selector: 'app-request-reset',
    templateUrl: './request-reset.component.html',
    styleUrls: ['./request-reset.component.scss']
})
export class RequestResetComponent implements OnInit {

    public form = {
        email: null
    };

    public error = null;

    constructor(
        private apiCalls: ApicallsService,
        private notify: SnotifyService,
        private expires: ExpirationCheckerService
    ) { }

    ngOnInit() {
        this.expires.tokenExpired();
    }

    onSubmit() {
        this.notify.info('Jeszcze chwilę ...', {timeout: 2000});
        this.apiCalls.sendPasswordResetLink(this.form).subscribe(
            data => this.handleResponse(data),
            error => {
                this.notify.error(error.error.error);
                this.handleError(error);
            }
        );
    }

    handleResponse(res) {
        this.notify.success(res.data, {timeout: 0});
        this.form.email = null;
    }

    handleError(error) {
        this.error = error.error.error;
    }
}
