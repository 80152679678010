import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private iss = {
        login: 'http://laravel-angular-auth-backend-001.test/api/v1/login',
        signup: 'http://laravel-angular-auth-backend-001.test/api/v1/signup'
    };

    constructor(
    ) { }

    static set(token) {
        localStorage.setItem('token', token);
    }

    static get(token) {
        return localStorage.getItem('token');
    }

    static remove() {
        localStorage.removeItem('token');
    }

    static decode(payload) {
        return JSON.parse(atob(payload));
    }

    static payload(token) {
        const payload = token.split('.')[1];
        return TokenService.decode(payload);
    }

    static handle(token) {
        TokenService.set(token);
    }

    isValid() {
        const token = TokenService.get('token');
        if (token) {
            const payload = TokenService.payload(token);
            if (payload) {
                return true;
            }
        }
        return false;
    }

    loggedIn() {
        return this.isValid();
    }
}
