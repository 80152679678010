import { Component, OnInit } from '@angular/core';
import { ExpirationCheckerService } from '../../services/expiration-checker.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    constructor(
        private expires: ExpirationCheckerService
    ) { }

    ngOnInit() {
        this.expires.tokenExpired();
    }

}
