import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApicallsService } from '../../../services/apicalls.service';
import { SnotifyService } from 'ng-snotify';
import { ExpirationCheckerService } from '../../../services/expiration-checker.service';

@Component({
    selector: 'app-response-reset',
    templateUrl: './response-reset.component.html',
    styleUrls: ['./response-reset.component.scss']
})
export class ResponseResetComponent implements OnInit {

    public form = {
        email: null,
        password: null,
        password_confirmation: null,
        resetToken: null
    };

    public error = [];

    constructor(
        private route: ActivatedRoute,
        private apiCalls: ApicallsService,
        private router: Router,
        private notify: SnotifyService,
        private expires: ExpirationCheckerService
    ) {
        route.queryParams.subscribe(params => {
            this.form.resetToken = params.token;
        });
    }

    ngOnInit() {
        this.expires.tokenExpired();
    }

    onSubmit() {
        this.apiCalls.changePassword(this.form).subscribe(
            data => this.handleResponse(data),
            error => {
                this.notify.error(error.error.error, {timeout: 2000});
                this.notify.error(Object.values(error.error.errors).toString(), {timeout: 2000});
                this.handleError(error);
            }
        );
    }

    handleResponse(data) {
        const router = this.router;
        this.notify.success('Gotowe, możesz się zalogować za pomocą nowego hasła', {
            timeout: 0,
            buttons: [
                {text: 'Okay',
                    action: toster => {
                        router.navigateByUrl('/login');
                        this.notify.remove(toster.id);
                    }
                }
            ]
        });
    }

    handleError(error) {
        this.error = error.error.errors;
    }

}
