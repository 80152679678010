import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class ExpirationCheckerService {

    public loggedIn: boolean;

    constructor(
        private auth: AuthService,
    ) {}

    tokenExpired() {
        this.auth.authStatus.subscribe( value => this.loggedIn = value);
        const token = TokenService.get('token');
        if (token) {
            const payload = TokenService.payload(token);
            const timestamp = Math.floor(Date.now()  / 1000);
            const expireOn = payload.exp;
            const logoutTime = ((Math.floor(expireOn - timestamp) / 60) - 55);
            if (payload) {
                if (logoutTime >= 0) {
                    this.auth.changeAuthStatus(true);
                } else {
                    this.auth.changeAuthStatus(false);
                }
            }
        }
    }
}
